@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

*{
  padding: 0;
  margin: 0;
  font-family: 'poppins';
  /* background-color: #f2f2f2; */
  
}
/* 
body{
  background-image: url('../public/background-11.png');
  background-color:rgba(173, 216, 230, 0.192);
} 
*/

.background-white{
  background-color: white;
}

.navbar-logo-image{
  width: 50px;
  height: 50px;
  margin-right:10px;
}

.margin-container{
  margin:15px;
}

.flex-container{
  display: flex;
}

.align-self-start{
  align-self: flex-start;
}

.justify-content-center{
  justify-content: center;
}

.align-items-center{
  align-items: center;
}

.position-absolute{
  position: absolute;
}

.margin-x-small{
  margin: 0px 10px;
}

.flex-direction-row{
  flex-direction: row;
}

.flex-direction-column{
  flex-direction: column;
}

.margin-x-y-big{
  margin: 50px 50px;
}

.card-container{
  width: 230px;
  padding: 20px;
  margin: 20px;
  border: 2px solid #f3f3f3;
  border-radius: 3px;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.font-grey{
  color: grey;
}

.font-big{
  font-size: 25px;
}

.font-small{
  font-size: 15px;
}

.padding-bottom-small{
  padding-bottom: 15px;
}

.padding-top-small{
  padding-top: 5px;
}

.text-decoration-none{
  text-decoration: none;
}

.text-bold{
  font-weight: 500;
}

.text-blue{
  color: rgb(25, 118, 210);
}


.width-65{
  width: 67%;
}

.width-80{
  width: 80%;
}

.width-20{
  width: 20%;
}

.justify-space-between{
  justify-content: space-between;
}

.justify-content-end{
  justify-content: end;
}

.height-70p{
  height: 70px;
}

.absolute{
  margin: -12px 15px;
}

.div-illustration{
  background: #d7d7d7;
  height: 25px;
  padding: 10px;
  border-radius: 30px;
}

.background-grey{
  background-color: #d7d7d7;
}

.padding-side-small{
  padding: 0px 10px;
}

.padding-side-mid{
  padding: 0px 30px;
}

.padding-left-big{
  padding: 0px 0px 0px 40px;
}

.border-light{
  border: 2px solid #f2f2f2;
  border-radius: 4px;
}

.padding-container{
  padding: 25px;
}

.big-padding-container{
  padding: 50px;
}

.small-padding-container{
  padding: 5px;
}

.padding-top-down-mid{
  padding: 10px 0px;
}

.margin-top-down-mid{
  margin: 10px 0px;
}

.margin-top-down-big{
  margin: 50px auto;
}


.hr-grey{
  border: 1px solid #d7d7d7;
}

.login-container{
  width: 500px;
  height: 600px;
  border: 1px solid #f2f2f2;
  background-image: url('../public/background-11.png');
  box-shadow: rgba(99, 99, 99,0.5) 0px 2px 8px 0px;
  border-radius: 5px;
}

.login-background{
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  /* background-image: url('../public/background-13.png'); */
  background: burlywood;
  background-size: cover;
  background-attachment: fixed;
}

.margin-auto-big{
  margin: 50px auto;
}

.margin-big{
  margin: 20px 0px;
}

.padding-small-mid{
  padding: 10px 20px;
}

.input-label{
  margin: 35px 10px;
}

.input-p{
  margin: 10px;
}

.input:hover{
  border:1px solid black;
}


.input{
  width: 270px;
  padding: 10px 15px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  font-size: 15px;
}

.button-primary{
  width: 90%;
  margin: 10px auto;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: rgb(25, 118, 210);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02857em;
  cursor: pointer;
  padding: 6px 16px;
  border-radius: 4px;
  transition: all 200ms;
}



.button-primary:hover{
  background-color: #1565c0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.button-outline{
  width: 90%;
  margin: 10px auto;
  background-color: transparent;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border: 2px solid rgba(25, 118, 210, 0.856);
  color: rgba(25, 118, 210, 0.856);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02857em;
  cursor: pointer;
  padding: 6px 15px;
  transition: all 200ms;
}

.button-outline:hover{
  background-color: #1565c0;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.disabled{
  opacity: 0.7;
}

.font-red{
  color: #d50000;
}

.tiny-font{
  font-size: 0.8em;
}

.image-container{
  position: relative;
  right: 5px;
  top: 5px;
}

.map-container{
  width: 100%;
  height: 50vh;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card-body{
  border-radius: 0.25rem;
}

.map-container-2{
  width: 100%;
  height: 55vh;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.center-cell{
  vertical-align: middle;
}

.table-header{
  background-color: #405189 !important;
  color: white;
}

.margin-side-mid{
  margin: -65px 50px 0px 40px;
}

.profile-image{
  position: absolute;
  right: 10px;
  top: 10px;
}

.profile-img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}


.box-shadow{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


.margin-top-minus-mid{
  margin: -60px 15px;
}

.margin-auto{
  margin: 0px auto;
}

.float-right{
  float: right;
}

.underline{
  text-decoration: underline;
}

.font-blue{
  color:rgb(25, 118, 210);
}

.font-bold{
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  *{
    font-size: 9px;
  }

  .login-container{
    width: 80%;
  }

  .flex-none{
    display: block !important;
  }

  .input{
    width: 140px;
    font-size: 10px;
    padding: 5px;
  }

  .input-label{
    margin:20px 10px;
  }

  /* .button-primary{
    width: 70%;
    margin: 10px auto;
  }

  .button-outline{
    width: 70%;
    margin: 10px auto;
  } */

  .font-big{
    font-size: 15px;
  }

  .navbar-logo-image{
    width: 35px;
    height: 35px;
    cursor:pointer
  }

  .button-primary{
    font-size: 10px;
    padding: 6px 7px;
    margin: 10px 5px;
    width: 90%;
  }

  .button-outline{
    font-size: 10px;
    padding: 6px 7px;
    margin: 10px 5px;
    width: 90%;
  }

}